import { useChargeQtyAdjMaintenanceVM } from "presentation/hook/ChargeQtyAdj/useChargeQtyAdjMaintenanceVM";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeQtyAdjTablePanel from "./ChargeQtyAdjTablePanel";
const ChargeQtyAdjMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const chargeQtyAdjVM = useChargeQtyAdjMaintenanceVM();
    const { isBackMaster } = chargeQtyAdjState;

    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    chargeQtyAdjVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            chargeQtyAdjVM.onSearch().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [isBackMaster, chargeQtyAdjVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<ChargeQtyAdjTablePanel />}
            />
        </div>
    </>
}
export default memo(ChargeQtyAdjMaintenance); 
