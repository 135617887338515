import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ChargeQtyAdjMaintenanceConstant } from "./ChargeQtyAdjMaintenanceConstant";

let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_CHARGE_QTY_ADJ_VESSEL_COL_DEF: any[] = [
    {
        headerName: ChargeQtyAdjMaintenanceConstant.CHARGEON_COMPANY,
        field: 'chargeOnCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 240,
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.BILLTO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.ARR_DATETIME,
        field: 'arrDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        dataType: "dateTime"
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.DEP_DATETIME,
        field: 'depDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        dataType: "dateTime"
    },

    {
        headerName: ChargeQtyAdjMaintenanceConstant.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.SL_VESSEL_CODE,
        field: 'slVesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.SL_IB_VOYAGE_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.SL_OB_VOYAGE_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.SOA,
        field: 'soa',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.LOA,
        field: 'loa',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
    },
    {
        headerName: ChargeQtyAdjMaintenanceConstant.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}