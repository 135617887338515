import { ChargeQuantityAdjCntrEntity } from "./ChargeQuantityAdjCntrEntity"

export interface ChargeQtyAdjEntity {
    id: number,
    chargeOnCompany: string | null,
    consortiumCode: string | null,
    vesselCode: string | null,
    voyageCode: string | null,
    etd: Date | null,
    allowance: number | null,
    waiverNo: string | null,
    chargeType: string | null,
    subChargeType: string | null,
    blNo: string | null,
    soNo: string | null,
    uom: string | null,
    serviceType: string | null,
    customReferenceNo: string | null,
    handlingTerminal: string | null,
    activeInd: string | null,
    cntrs: ChargeQuantityAdjCntrEntity[] | null,
    cntrList: string[],
    displayCntrNos?: string | null,
    [key: string]: string | number | Date | Object[] | null | undefined
}
export const EMPTY_CHARGE_QTY_ADJ_ENTITY: ChargeQtyAdjEntity = {
    id: 0,
    chargeOnCompany: "",
    consortiumCode: "",
    vesselCode: "",
    voyageCode: "",
    activeInd: "Y",
    etd: null,
    allowance: null,
    waiverNo: "",
    chargeType: "",
    subChargeType: "",
    blNo: "",
    soNo: "",
    uom: "",
    serviceType: "",
    customReferenceNo: "",
    handlingTerminal: "",
    cntrs: [],
    cntrList: []
} 
