export interface ChargeQtyAdjSearchCriteria{
    
    billToCompany?: string | null,
    chargeOnCompany?: string | null,
    conCode?: string| null,
    vslCode?: string| null,
    voyCode?: string | null,
    vslName?: string | null,
    arrDate?: Date | null,
    depDate?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA : ChargeQtyAdjSearchCriteria = {
    billToCompany: "",
    chargeOnCompany: "",
    conCode: "",
    vslCode: "",
    voyCode: "",
    vslName: "",
    arrDate: null,
    depDate: null,
}