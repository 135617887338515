import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChargeQtyAdjMaintenanceProvider } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ChargeQtyAdjMasterView } from "presentation/view/section/ChargeQtyAdj/ChargeQtyAdjMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";
const ChargeQtyAdjMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.CHARGE_QTY_ADJ_MAINTENANCE}>
        <MessageBarWrapper>
            <ChargeQtyAdjMaintenanceProvider>
                <GridStyles />
                <ChargeQtyAdjMasterView />
            </ChargeQtyAdjMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}
export default ChargeQtyAdjMaintenanceCont; 
