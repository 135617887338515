import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeQtyAdjEntity } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjEntity";
import { ChargeQtyAdjSearchCriteria } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjSearchCriteria";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ChargeQtyAdjRepository } from "./ChargeQtyAdjRepo";
/** 
 * For template 
 * @returns  
 */
export const ChargeQtyAdjRepoImpl = (): ChargeQtyAdjRepository => {
    const url = '/v1/chargeQtyAdj';
    const urlUpload = '/v1/chargeQtyAdjUpload'
    const getEntities = async (): Promise<ChargeQtyAdjEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });

    }
    const getEntityByKey = async (key: string): Promise<ChargeQtyAdjEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }
    const createEntity = async (newData: ChargeQtyAdjEntity): Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }
    const updateEntity = async (updatedData: ChargeQtyAdjEntity): Promise<ResponseEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }
    const deleteEntity = async (key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }

    const uploadData = async (csvData: ChargeQtyAdjEntity[]): Promise<ResponseEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${urlUpload}`, csvData)
    }

    const getConVslVoyForChargeQtyAdj = async (vesselSearchCriteria:ChargeQtyAdjSearchCriteria): Promise<ChargeDetailEntity[]> => {
        const newUrl = "/v1/searchConVslVoyForChargeQtyAdj";
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, vesselSearchCriteria).then((res) => {
            const data = res.data;
            return data;
        }).catch((error) => {
            return [];
        })

    }
    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
        uploadData: uploadData,
        getConVslVoyForChargeQtyAdj: getConVslVoyForChargeQtyAdj,
    }
} 
