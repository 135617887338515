import { ChargeQtyAdjRepoImpl } from "domain/repository/ChargeQtyAdj/ChargeQtyAdjRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { ChargeQtyAdjMaintenanceVM } from "presentation/viewModel/ChargeQtyAdj/ChargeQtyAdjMaintenanceVM";
import { useMemo } from "react";
export const useChargeQtyAdjMaintenanceVM = () => {
    const [, setChargeQtyAdjMaintenanceState] = useChargeQtyAdjMaintenanceTracked();
    const ChargeQtyAdjMainVM = useMemo(() =>
        ChargeQtyAdjMaintenanceVM({
            dispatch: [setChargeQtyAdjMaintenanceState],
            repo: ChargeQtyAdjRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setChargeQtyAdjMaintenanceState])
    return ChargeQtyAdjMainVM
} 
