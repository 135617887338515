import * as yup from 'yup';
export const commonChargeQtyAdjMaintenanceValidation: { [x: string]: any; } = {
  chargeType: yup.string().required("Missing"),
  allowance: yup.string().required("Missing"),
  waiverNo: yup.string().required("Missing"),
  cntrList: yup.array().min(1, "Missing").required("Missing"),
  uom: yup.string().required("Missing"),
};
export const createChargeQtyAdjMaintenanceValidation: { [x: string]: any; } = {
  ...commonChargeQtyAdjMaintenanceValidation,
};
export const createChargeQtyAdjMaintenanceValidationSchema = yup.object().shape(createChargeQtyAdjMaintenanceValidation); 
