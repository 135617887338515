import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeQtyAdjEntity, EMPTY_CHARGE_QTY_ADJ_ENTITY } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjEntity";
import { ChargeQtyAdjSearchCriteria, EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
export interface ChargeQtyAdjDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    chargeOnCompDropdownOptions: DropdownProps[],
    handlingTmlDropdownOptions: DropdownProps[],
    consortiumCodeDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: ChargeQtyAdjEntity,
}
export interface ChargeQtyAdjMaintenanceModel {
    isLoading: boolean,
    tableData: ChargeQtyAdjEntity[],
    currentSelectedRow: ChargeQtyAdjEntity,
    selectedRows: ChargeQtyAdjEntity[],
    dynamicOptions: ChargeQtyAdjDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
    isShowFindVesselPanel: boolean,
    chargeQtyAdjVesselData: ChargeDetailEntity[],
    selectVeeselDatas: ChargeDetailEntity[],
    vesselSearchCriteria: ChargeQtyAdjSearchCriteria,
}
export const EMPTY_MASTER_CHARGE_QTY_ADJANY_MODEL: ChargeQtyAdjMaintenanceModel = {
    isLoading: false,
    isShowFindVesselPanel: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_CHARGE_QTY_ADJ_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        chargeOnCompDropdownOptions: [],
        handlingTmlDropdownOptions: [],
        consortiumCodeDropdownOptions: [],
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_CHARGE_QTY_ADJ_ENTITY,
    },
    isBackMaster: false,
    chargeQtyAdjVesselData: [],
    selectVeeselDatas: [],
    vesselSearchCriteria: {...EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA}
} 
