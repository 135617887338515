import { ChargeQtyAdjMaintenanceConstant } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceConstant";
import { useChargeQtyAdjMaintenanceVM } from "presentation/hook/ChargeQtyAdj/useChargeQtyAdjMaintenanceVM";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { memo } from "react";
import { DialogModal, IconButton } from "veronica-ui-component/dist/component/core";
import ChargeQtyAdjVesselContent from "./ChargeQtyAdjVesselContent";

const ChargeQtyAdjVesselComp:React.FC = () => {
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const chargeQtyAdjVM = useChargeQtyAdjMaintenanceVM();
    const { isShowFindVesselPanel } = chargeQtyAdjState;


    const handleCloseDialog = () => {        
        chargeQtyAdjVM.closeVesselPanel();
    }
    return <DialogModal style={{  minWidth:'62vw', minHeight:'82vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowFindVesselPanel}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{ChargeQtyAdjMaintenanceConstant.CO_VSL_VOY}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={handleCloseDialog} tooltipDisable={true}/>
            </div>
        }
        dialogContent={<ChargeQtyAdjVesselContent/>}        
        className="im-charge-data-search-confirm-modal"
    />
}

export default memo(ChargeQtyAdjVesselComp);